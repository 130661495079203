<!--
 * @Descripttion:
 * @version:
 * @Author: 顾俊杰
 * @Date: 2020-12-09 17:20:58
 * @LastEditors: 顾俊杰
 * @LastEditTime: 2020-12-11 17:20:48
-->
<template>
  <div>
    <div class="content">
      <div class="dataframe">
        <div
          class="con_menu"
          v-for="(itemt, indexb) in showfile"
          :key="indexb"
          @click="dianjipdf(itemt)"
        >
          <div class="con_menu-title">
            <p class="ziliaomc">{{ itemt.filename }}</p>
            <p class="original">查看原文</p>
          </div>
          <div style="margin: auto;">
            <md-icon name="arrow-right" size="md" style=""></md-icon>
          </div>
        </div>
        <div class="asdasd" v-show="showfile.length == 0">
          <img src="@/assets/nh/nhwxcpnull.jpg" alt="" />
          <p class="asdasdqwe">哎呀 暂无相关资料呢~</p>
        </div>
      </div>
    </div>
    <md-landscape v-model="showFullScreen" full-screen class="landscape">
      <div class="Iconx">
        <md-icon
          name="clear"
          size="lg"
          class="Iconclear"
          @click="close"
        ></md-icon>
      </div>
      <div class="pdf" style="padding-top: 1rem;">
        <div>
          <canvas
            v-for="page in pages"
            :id="'the-canvas' + page"
            :key="page"
          ></canvas>
        </div>
      </div>
    </md-landscape>
  </div>
</template>

<script>
import PDFJS from 'pdfjs-dist'
import { selectJhsCpInfo } from '@/api/jhs/productlist/index'
import { getStorage } from '@/lib/util'
export default {
  data () {
    return {
      iosfile: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      pdfDoc: null,
      pages: 0,
      ossurl: '',
      prodcode: '',
      code: '',
      showfile: [],
      showFullScreen: false,
      numPages: 0
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.prodcode = this.$route.query.prodcode
    this.code = this.$route.query.code
    this.information()
  },
  methods: {
    information () {
      // type 传1为查询showmsg产品资料，即外层点击;传2在点击查看PDF文件时调用;
      selectJhsCpInfo({ prodcode: this.prodcode, type: '2' }).then(res => {
        let arr = res.data.data.showfile
        arr.forEach((item, index) => {
          if (item.code == this.code) {
            this.showfile = item.list
          }
        })
      })
    },
    // 点击查看pdf
    dianjipdf (val) {
      this.showFullScreen = true
      this.AloadFile(this.ossurl + val.filehttp)
    },
    close () {
      this.showFullScreen = false
    },
    ArenderPage (num) {
      let _this = this
      this.pdfDoc.getPage(num).then(function (page) {
        let canvas = document.getElementById('the-canvas' + num)
        let ctx = canvas.getContext('2d')
        let dpr = window.devicePixelRatio || 1
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1
        let ratio = dpr / bsr
        var viewport = page.getViewport(
          screen.availWidth / page.getViewport(1).width
        )
        canvas.width = viewport.width * ratio
        canvas.height = viewport.height * ratio
        canvas.style.width = viewport.width + 'px'
        canvas.style.height = viewport.height + 'px'
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport
        }
        page.render(renderContext)
        if (_this.pages > num) {
          _this.ArenderPage(num + 1)
        }
        _this.$forceUpdate()
      })
    },
    AloadFile (url) {
      let _this = this
      let data = {
        url: url,
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.4.456/cmaps/',
        cMapPacked: true
      }
      PDFJS.disableRange = true
      PDFJS.disableStream = true
      PDFJS.getDocument(data).then(function (pdf) {
        _this.pdfDoc = pdf
        _this.pages = _this.pdfDoc.numPages
        _this.$nextTick(() => {
          _this.ArenderPage(1)
        })
      })
    }
  }
}
</script>

<style scoped>
.content {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0 1.5rem 0;
  background-color: #ffffff;
}
.con_menu-title {
  margin-top: 30px;
  margin-left: 0.9rem;
  margin-bottom: 0.3rem;
}
.con_menu {
  margin-top: 0.3rem;
  display: flex;
  align-content: center;
  background-color: #f6f6f6;
}
.landscape /deep/ .md-landscape-close {
  display: none !important;
}
.Iconx {
  margin-top: 0.2rem;
  margin-right: 0.3rem;
  right: 0;
  position: absolute;
}

.Iconclear {
  color: rgba(148, 148, 148, 0.6) !important;
  font-size: 0.7rem !important;
}
.asdasd {
  width: 100%;
  height: auto;
  margin-top: 5rem;
}
.asdasdqwe {
  text-align: center;
  color: #c4c4c4;
}
.original {
  color: #989898;
  font-size: 0.3rem;
}
.ziliaomc {
  width: 8rem;
  color: #383838;
}
</style>
